<template>
  <v-container fluid class="wp-page events">
    <page-title class="hidden-xs-only" :page="page" />
    <Breadcrumb :items="breadcrumb" />
    <v-container>
      <v-runtime-template :template="page.content.rendered" @click="clicked" />
    </v-container>
    <div class="bg-grey">
      <v-container
        ><h2 class="mt-4 secondary--text">
          {{ $t("events.futureEvents.title") }}
        </h2>
        <EventListSlider from="future"></EventListSlider>
      </v-container>
    </div>

    <v-container class="d-flex flex-column">
      <div class="mt-4 d-flex align-center">
        <h2 class="secondary--text">
          {{ $t("events.pastEvents.title") }}
        </h2>
        <v-btn
          small
          elevation="0"
          color="primary"
          class="ml-2"
          :to="{
            name: 'PastEvents'
          }"
          >{{ $t("events.pastEvents.showAll") }}</v-btn
        >
      </div>
      <EventListSlider from="past"></EventListSlider>
    </v-container>
    <BeCustomerCardHorizontal
      card="registrationHome"
      v-if="
        !isAuthenticated && this.page && this.page.slug != 'diventa-cliente'
      "
    />
    <FaqBar class="mt-5" v-else-if="this.page && this.page.banner_faq == '1'" />
    <NewsletterHorizontal />
  </v-container>
</template>
<style lang="scss" scoped>
.wp-page.events {
  padding: 0;
}
.bg-grey {
  background-color: #f8f8f8;
}
</style>
<script>
import EventListSlider from "@/components/wordpress/EventListSlider.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PageTitle from "@/components/wordpress/PageTitle.vue";
import BeCustomerCardHorizontal from "@/components/cards/BeCustomerCardHorizontal.vue";
import FaqBar from "@/components/registration/FaqBar.vue";
import NewsletterHorizontal from "@/components/cards/NewsletterHorizontal.vue";
import page from "~/mixins/page";

import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";
import { mapGetters } from "vuex";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "Events",
  mixins: [page, clickHandler],
  data() {
    return {
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    PageTitle,
    Breadcrumb,
    BeCustomerCardHorizontal,
    FaqBar,
    NewsletterHorizontal,
    EventListSlider
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    })
  },
  created() {
    if (this.$route.params.additionalData) {
      this.additionalData = this.$route.params.additionalData;
    }
    //this.fetchPage(this.$route.params.pageName);
    this.page = this.$route.params.page;
  },
  watch: {
    "$route.params.pageName": function() {
      this.page = this.$route.params.page;
      this.key++;
    }
  }
};
</script>
